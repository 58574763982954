import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import preview from "../../../images/preview.png"

const SEO = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  return (
    <Helmet>
      <html lang={site.siteMetadata.lang} />
      <meta charSet="utf-8"></meta>
      <meta name="description" content={site.siteMetadata.description} />
      <meta property="og:title" content={site.siteMetadata.title} />
      <meta property="og:description" content={site.siteMetadata.description} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={preview} />
      <title>{site.siteMetadata.title}</title>
    </Helmet>
  )
}

export default SEO