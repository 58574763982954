import React from "react"
import SEO from "./seo"

const Layout = ({ children }) => (
  <>
    <SEO />
    <div className="font-display">
      {children}
    </div>
  </>
)

export default Layout
